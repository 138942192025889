exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-markdown-remark-frontmatter-slug-jsx": () => import("./../../../src/pages/{markdownRemark.frontmatter__slug}.jsx" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-jsx" */),
  "component---src-pages-nachspielzeit-index-tsx": () => import("./../../../src/pages/nachspielzeit/index.tsx" /* webpackChunkName: "component---src-pages-nachspielzeit-index-tsx" */),
  "component---src-pages-sommerfest-2024-tsx": () => import("./../../../src/pages/sommerfest-2024.tsx" /* webpackChunkName: "component---src-pages-sommerfest-2024-tsx" */)
}

